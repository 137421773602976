/* eslint-disable no-restricted-imports */
import { ButtonProps } from "@chakra-ui/react";
import { WatchlistButtonItself } from "./components/watchlist-button-itself";
import { WatchlistToggle } from "./components/watchlist-toggle";

interface IWatchlistButtonProps extends Omit<ButtonProps, "isLoading" | "onClick"> {
    wikifolioId: string;
    wikifolioShortDescription: string;
    isOnWatchlist?: boolean;
    omitTitle?: boolean;
    addedToWatchlistMessage?: string;
    removedFromWatchlistMessage?: string;
    setOnWatchlist: (wikifolioId: string, isOnWatchlist: boolean) => void;
}

export const WatchlistButton = ({
    wikifolioId,
    wikifolioShortDescription,
    isOnWatchlist,
    omitTitle = false,
    addedToWatchlistMessage,
    removedFromWatchlistMessage,
    setOnWatchlist,
    onPointerMove,
    ...buttonProps
}: IWatchlistButtonProps) => (
    <>
        <WatchlistToggle
            wikifolioId={wikifolioId}
            wikifolioShortDescription={wikifolioShortDescription}
            isOnWatchlist={isOnWatchlist}
            addedToWatchlistMessage={addedToWatchlistMessage}
            removedFromWatchlistMessage={removedFromWatchlistMessage}
            setOnWatchlist={setOnWatchlist}
        >
            {({ isLoading, onClick }) => (
                <WatchlistButtonItself
                    wikifolioShortDescription={wikifolioShortDescription}
                    omitTitle={omitTitle}
                    isOnWatchlist={isOnWatchlist}
                    isLoading={isLoading}
                    {...buttonProps}
                    onClick={onClick}
                />
            )}
        </WatchlistToggle>
    </>
);
