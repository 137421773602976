import { useCallback, useEffect, useRef, useState } from "react";
import { Box, BoxProps, forwardRef, Text, useBoolean } from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { useTranslation } from "next-i18next";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { useElementFirstSeen } from "src/hooks/use-element-first-seen";
import { SpanBox } from "../base";
import { CommentRichText } from "./rich-text/comment-rich-text";

const WAIT_FOR_CHECK_MILLISECONDS = 350;
const DEBOUNCE_OPTION = { leading: false };
const EXPANDABLE_TEXT_EXPAND_TEST_ID = "expandable-text-expand";

interface IFormattedTextProps extends Omit<BoxProps, "fontSize"> {
    formattingType: "plainText" | "userHtml" | "umbracoHtml";
    text: string;
}
const FormattedText = forwardRef<IFormattedTextProps, "div">(({ formattingType, text, ...props }: IFormattedTextProps, ref) => {
    switch (formattingType) {
        case "plainText":
            return (
                <Text ref={ref} {...props}>
                    {text}
                </Text>
            );
        case "umbracoHtml":
            return <SimpleRichText ref={ref} text={text} {...props} />;
        case "userHtml":
            return <CommentRichText ref={ref} text={text} {...props} />;
    }
});

export interface IExpandableTextProps extends BoxProps {
    text: string;
    formattingType: "plainText" | "userHtml" | "umbracoHtml";
    textProps?: Omit<BoxProps, "fontSize">;
    noOfLines?: number;
    canCalculateHeight?: boolean;
}

export const ExpandableText = ({ text, noOfLines = 4, formattingType, textProps, canCalculateHeight = true, ...boxProps }: IExpandableTextProps) => {
    const { t } = useTranslation("common");

    const [isExpanded, setExpanded] = useBoolean(false);
    const [isTextExpandable, setTextExpandable] = useState(false);
    const [commentHeight, setCommentHeight] = useState("unset");
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current || !canCalculateHeight) {
            return;
        }
        const commentHeight = `${parseInt(getComputedStyle(ref.current).lineHeight) * noOfLines}px`;
        setCommentHeight(commentHeight);
    }, [ref, noOfLines, canCalculateHeight]);

    const checkIsTextExpandable = useCallback(() => {
        if (!ref.current) return;

        setTextExpandable(ref.current!.scrollHeight > ref.current!.clientHeight);
    }, [ref]);
    const checkIsTextExpandableDebounced = useCallback(
        () => debounce(checkIsTextExpandable, WAIT_FOR_CHECK_MILLISECONDS, DEBOUNCE_OPTION),
        [checkIsTextExpandable]
    );

    useEffect(() => {
        checkIsTextExpandable(); // init check

        window.addEventListener("resize", checkIsTextExpandableDebounced);

        return () => {
            window.removeEventListener("resize", checkIsTextExpandableDebounced);
        };
    }, [checkIsTextExpandable, checkIsTextExpandableDebounced]);

    useElementFirstSeen(ref, () => {
        checkIsTextExpandable();
    });

    useEffect(() => {
        if (!isExpanded || !canCalculateHeight) {
            return;
        }

        // set the height to full content - in order to start the animation
        ref.current!.style.height = ref.current!.scrollHeight + "px";
    }, [isExpanded, canCalculateHeight]);

    const handleExpandClick = () => {
        // Set the height of the element to its current height - in order to be able to animate the height
        ref.current!.style.height = ref.current!.clientHeight + "px";
        setExpanded.on();
    };

    const handleTransitionEnd = () => {
        // Reset the hardcoded height after animation ended
        // So the height is dynamically again if the content layout changes
        ref.current!.style.height = "";
    };

    const heightAnimationProps = {
        overflow: "hidden",
        transition: "height 0.3s",
        noOfLines: isExpanded ? undefined : noOfLines,
        maxHeight: isExpanded ? "100%" : commentHeight,
    };

    return (
        <Box position="relative" {...boxProps}>
            <FormattedText
                ref={ref}
                formattingType={formattingType}
                text={text}
                onTransitionEnd={handleTransitionEnd}
                {...heightAnimationProps}
                {...textProps}
            />
            {isTextExpandable && !isExpanded && (
                <Box
                    position="absolute"
                    bottom={0}
                    w="100%"
                    textAlign="right"
                    backgroundImage="linear-gradient(rgba(255,255,255,0.01), rgba(255,255,255,0.6), rgba(255,255,255,1))"
                >
                    <SpanBox
                        role="button"
                        px={2}
                        color="green.600"
                        bgColor="white"
                        fontWeight="semibold"
                        onClick={handleExpandClick}
                        data-test-id={EXPANDABLE_TEXT_EXPAND_TEST_ID}
                    >
                        {t("read-more")}
                    </SpanBox>
                </Box>
            )}
        </Box>
    );
};
