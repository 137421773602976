import { RefObject, useEffect, useMemo } from "react";
import { isServerSide } from "src/utils/common-util";

export const useElementFirstSeen = (ref: RefObject<HTMLElement>, onSeenCallback?: () => void) => {
    const intersectionObserver = useMemo(() => {
        if (!isServerSide() && typeof IntersectionObserver !== "undefined") {
            return new IntersectionObserver(
                function (this: IntersectionObserver, entries) {
                    if (entries && entries[0].isIntersecting) {
                        onSeenCallback?.();
                        this.disconnect();
                    }
                },
                { threshold: 1.0 }
            );
        }
    }, [onSeenCallback]);

    useEffect(() => {
        if (intersectionObserver && ref && ref.current) {
            intersectionObserver.observe(ref.current);
        }
    }, [intersectionObserver, ref]);
};
