import { MouseEvent } from "react";
import { useBoolean, useDisclosure } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { useCustomActivityMutation } from "src/api/client/custom-activity.api";
import { EnumActivityType, useGenericActivityMutation } from "src/api/client/generic-activity.api";
import { Button, ButtonProps } from "src/components/base/button";
import { IInvestNowModalProps } from "src/components/modals/invest-now-modal/invest-now-modal";

interface IInvestButtonProps extends ButtonProps {
    wikifolioId: string;
    isin: string;
    wikifolioSecurityId?: string;
}

const InvestNowModal = dynamic<IInvestNowModalProps>(
    () => import("src/components/modals/invest-now-modal/invest-now-modal").then(component => component.InvestNowModal),
    {
        ssr: false,
    }
);

export const InvestButton = ({ isin, wikifolioId, wikifolioSecurityId, onClick, children, ...buttonProps }: IInvestButtonProps) => {
    const { t } = useTranslation("common");
    const modalDisclosure = useDisclosure();
    const [isLoading, setLoading] = useBoolean();
    const createCustomActivity = useCustomActivityMutation();
    const genericActivity = useGenericActivityMutation();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setLoading.on();
        modalDisclosure.onOpen();
        onClick?.(event);
    };

    return (
        <>
            <Button
                onClick={handleOnClick}
                {...buttonProps}
                className="gtm-invest-button"
                isLoading={isLoading}
                data-isin={isin}
                data-gtm-action="invest click"
            >
                {children || t("invest")}
            </Button>

            {modalDisclosure.isOpen && (
                <InvestNowModal
                    isin={isin}
                    wikifolioId={wikifolioId}
                    wikifolioSecurityId={wikifolioSecurityId}
                    onRequestSuccess={() => {
                        createCustomActivity({ activity: "InvestClicked", value: isin });
                        genericActivity({
                            activityTypeId: EnumActivityType.InvestButtonPressed,
                            parameter: `wikifolioId=${wikifolioId}`,
                        });
                    }}
                    onRequestSettled={setLoading.off}
                    {...modalDisclosure}
                />
            )}
        </>
    );
};
