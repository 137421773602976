import { Box, BoxProps, Flex, FlexProps, HStack } from "@chakra-ui/react";
import { DescriptionDefinition, DescriptionTerm } from "src/components/base";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { IFractionDigits } from "src/hooks/formatters/number-formatter";
import { ITooltip } from "src/types/common";
import { IRankingValueSize, RankingValue } from "./ranking-value";
import { IRankingData } from "./types";

export interface IRanking {
    size: IRankingValueSize;
    fractionDigits?: IFractionDigits;
    tooltip?: ITooltip | null;
    data: IRankingData;
    isDescriptionListItem?: boolean;
    hidePoints?: boolean;
    labelProps?: BoxProps;
}

export interface IRankingProps extends IRanking, FlexProps {}

export const Ranking = ({ data, size, fractionDigits, tooltip, isDescriptionListItem, hidePoints, labelProps, ...flexProps }: IRankingProps) => {
    return (
        <Flex flexDir="column" align="center" overflow="hidden" mx={0.5} {...flexProps}>
            <Box as={isDescriptionListItem ? DescriptionTerm : Box} fontWeight="bold" maxW="100%">
                <RankingValue size={size} data={data} fractionDigits={fractionDigits} hidePoints={hidePoints} />
            </Box>
            <HStack as={isDescriptionListItem ? DescriptionDefinition : Box} alignItems="center" spacing={0.5} maxW="100%">
                <Box as="span" color="gray.500" fontSize="xs" noOfLines={1} wordBreak="break-all" {...labelProps}>
                    {data.label}
                </Box>
                {tooltip && (
                    <WfPopover {...tooltip}>
                        <PopoverButtonIconInfo ariaLabel={data.label} />
                    </WfPopover>
                )}
            </HStack>
        </Flex>
    );
};
