import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconPlay: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" border="1px" rounded="50%" borderColor="current" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                transform="rotate(90 12 12) scale(0.8) translate(3, 1)"
                d="m12.814 6.1392 8.0568 11.28c0.32101 0.44941 0.21692 1.074-0.2325 1.395-0.1696 0.12114-0.37282 0.18627-0.58124 0.18627h-16.114c-0.55228 0-1-0.44772-1-1 0-0.20842 0.065123-0.41164 0.18627-0.58124l8.0568-11.28c0.32101-0.44941 0.94556-0.5535 1.395-0.2325 0.089796 0.06414 0.16836 0.1427 0.2325 0.2325z"
                fill="currentColor"
            />
        </g>
    </Icon>
);
