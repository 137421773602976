import { proxy, useSnapshot } from "valtio";

export const wikifolioStore = proxy({
    watchlist: {} as { [key: string]: boolean },

    setOnWatchlist(wikifolioId: string, isOnWatchlist: boolean) {
        wikifolioStore.watchlist[wikifolioId] = isOnWatchlist;
    },

    canBeFollowedWikifolios: {} as Record<string, boolean>,

    setCanBeFollowedWikifolio(wikifolioId: string, canBeFollowed: boolean) {
        wikifolioStore.canBeFollowedWikifolios[wikifolioId] = canBeFollowed;
    },
});

export function useWikifolioStore() {
    return useSnapshot(wikifolioStore);
}
