import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { useCommentRichText } from "src/components/common/rich-text/use-comment-rich-text";

// xl is the biggest font-size for text-content
type IFontSizeKeys = "xs" | "sm" | "md" | "lg" | "xl";
export type ICommentRichTextFontSize = IFontSizeKeys | IFontSizeKeys[];

export interface ICommentRichTextProps extends Omit<BoxProps, "fontSize"> {
    text: string;
    fontSize?: ICommentRichTextFontSize;
}

export const CommentRichText = forwardRef<ICommentRichTextProps, typeof Box>(({ text, fontSize, ...boxProps }: ICommentRichTextProps, ref) => {
    const sx = useCommentRichText();

    // Dev-Note: uncomment when we want to use Next.js agnostic links
    // useNextLink(ref);

    return <Box ref={ref} fontSize={fontSize} sx={sx} dangerouslySetInnerHTML={{ __html: text }} {...boxProps} />;
});
