// TODO: https://wikifolio.atlassian.net/browse/WIKI-35898
// eslint-disable-next-line no-restricted-imports
import { Button, ButtonProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IconFilledStar, IconOutlineStar } from "src/components/icons";

interface IWatchlistButtonItselfProps extends ButtonProps {
    wikifolioShortDescription: string;
    isOnWatchlist?: boolean;
    omitTitle?: boolean;
}

export const WatchlistButtonItself = ({ wikifolioShortDescription, isOnWatchlist, omitTitle, ...buttonProps }: IWatchlistButtonItselfProps) => {
    const { size } = buttonProps;
    const IconStar = isOnWatchlist ? IconFilledStar : IconOutlineStar;
    const { t } = useTranslation("common");
    const text = isOnWatchlist ? t("remove") : t("watchlist");

    return (
        <Button
            leftIcon={<IconStar boxSize={size === "xs" ? 2 : 3} />}
            variant="outline"
            data-gtm-wikifolio-short-description={wikifolioShortDescription}
            className={isOnWatchlist ? undefined : "gtm-watchlist__add"}
            aria-label={t("watchlist")}
            {...buttonProps}
        >
            {!omitTitle && text}
        </Button>
    );
};
