import { Children, cloneElement, JSXElementConstructor, ReactElement, ReactNode, useEffect, useState } from "react";
import { MenuItemProps, Menu, MenuProps, MenuButton, MenuItem, MenuList, MenuListProps, useMultiStyleConfig, BoxProps, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IconCaret } from "src/components/icons";
import { SpanFlex } from ".";

// Dev-Note: we need to use object here so that we support interface types
// eslint-disable-next-line @typescript-eslint/ban-types
type PossibleValueTypes = string | number | object;

interface IComboBoxItemProps<ValueType extends PossibleValueTypes> extends Omit<MenuItemProps, "value"> {
    value: ValueType;
    onClick?: () => void;
}

type IComboBoxItem<ValueType extends PossibleValueTypes> = ReactElement<
    IComboBoxItemProps<ValueType>,
    JSXElementConstructor<IComboBoxItemProps<ValueType>>
>;

export const ComboBoxItem = <ValueType extends PossibleValueTypes>({ value, onClick, ...menuItemProps }: IComboBoxItemProps<ValueType>) => {
    return <MenuItem onClick={onClick} {...menuItemProps} />;
};

interface IComboBoxProps<ValueType extends PossibleValueTypes> extends Omit<MenuProps, "children"> {
    placeholder?: ReactNode;
    value: ValueType | null;
    onChange?: (value: ValueType, label: ReactNode) => void;
    children: IComboBoxItem<ValueType>[];
    isDisabled?: boolean;
    menuListProps?: MenuListProps;
    inputBoxProps?: BoxProps;
    dataTestId?: string;
}

export const ComboBox = <ValueType extends PossibleValueTypes>({
    placeholder,
    value,
    size,
    onChange,
    children,
    isDisabled = false,
    menuListProps,
    inputBoxProps = {},
    dataTestId,
    ...props
}: IComboBoxProps<ValueType>) => {
    const { field: inputStyle } = useMultiStyleConfig("Input", { size });
    const [label, setLabel] = useState<ReactNode | null>(null);
    const { t } = useTranslation("common");

    // if the value changes we need to update the label accordingly
    useEffect(() => {
        if (value === 0 || value) {
            const label = children.find(child => JSON.stringify(child.props.value) === JSON.stringify(value));
            setLabel(label ? label.props.children : null);
        } else {
            setLabel(null);
        }
    }, [children, value]);

    const showPlaceholder = value === null;

    return (
        <Box data-test-id={dataTestId}>
            <Menu {...props}>
                {({ isOpen }) => (
                    <>
                        <MenuButton pos="relative" disabled={isDisabled} sx={{ ...inputStyle }}>
                            <SpanFlex align="center" color={showPlaceholder ? "gray.500" : undefined} pr={6} {...inputBoxProps}>
                                {showPlaceholder ? placeholder || t("choose") : label}
                            </SpanFlex>
                            <SpanFlex pos="absolute" insetY={0} right={2} align="center">
                                <IconCaret
                                    boxSize={3}
                                    color={isDisabled ? "gray.200" : "inherit"}
                                    transition="transform 0.3s"
                                    transform={isOpen ? "rotate(180deg)" : undefined}
                                />
                            </SpanFlex>
                        </MenuButton>
                        <MenuList overflow="auto" {...menuListProps}>
                            {Children.map<ReactNode, IComboBoxItem<ValueType>>(children, child => {
                                return cloneElement(child, {
                                    onClick: onChange ? () => onChange(child.props.value, child.props.children) : undefined,
                                });
                            })}
                        </MenuList>
                    </>
                )}
            </Menu>
        </Box>
    );
};
