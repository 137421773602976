import { MouseEvent, ReactNode } from "react";
import { useClipboard } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useCustomActivityMutation } from "src/api/client/custom-activity.api";
import { Button, ButtonProps } from "src/components/base/button";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";

interface ICopyIsinButtonProps extends ButtonProps {
    isin: string;
    children?: ReactNode;
}

export const CopyIsinButton = ({ isin, onClick, children, ...buttonProps }: ICopyIsinButtonProps) => {
    const { t } = useTranslation("common");
    const { onCopy } = useClipboard(isin);
    const toast = useWfToast();
    const createCustomActivity = useCustomActivityMutation();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        onCopy();
        toast({ status: EnumToastStatus.Info, title: t("copy-isin-success") });
        onClick?.(event);
        createCustomActivity({ activity: "CopiedIsin", value: isin });
    };

    return (
        <Button onClick={handleOnClick} {...buttonProps} className="gtm-copy-isin-button" data-isin={isin}>
            {children || t("copy-isin")}
        </Button>
    );
};
