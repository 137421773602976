import { proxy, useSnapshot } from "valtio";

export type TIsinWithFollowData = {
    isin: string;
    isFollowed: boolean;
    canBeFollowed: boolean;
};

export type TUnderlyingFollowStoreData = Record<string, Omit<TIsinWithFollowData, "isin">>;

export const underlyingFollowStore = proxy({
    underlyingsFollowData: {} as TUnderlyingFollowStoreData,

    setUnderlyingsFollowData(underlyingsFollowData: TUnderlyingFollowStoreData) {
        underlyingFollowStore.underlyingsFollowData = underlyingsFollowData;
    },

    setIsFollowedUnderlying(underlyingIsin: string, isFollowed: boolean) {
        underlyingFollowStore.underlyingsFollowData[underlyingIsin] = {
            ...underlyingFollowStore.underlyingsFollowData[underlyingIsin],
            isFollowed,
        };
    },

    setCanBeFollowedUnderlying(underlyingIsin: string, canBeFollowed: boolean) {
        underlyingFollowStore.underlyingsFollowData[underlyingIsin] = {
            ...underlyingFollowStore.underlyingsFollowData[underlyingIsin],
            canBeFollowed,
        };
    },

    get canBeFollowedUnderlyingIsins(): string[] {
        return Object.keys(underlyingFollowStore.underlyingsFollowData).filter(key => underlyingFollowStore.underlyingsFollowData[key].canBeFollowed);
    },

    get followedUnderlyingIsins(): string[] {
        return Object.keys(underlyingFollowStore.underlyingsFollowData).filter(key => underlyingFollowStore.underlyingsFollowData[key].isFollowed);
    },
});

export function useUnderlyingFollowStore() {
    return useSnapshot(underlyingFollowStore);
}
