import { SystemStyleObject, useStyleConfig } from "@chakra-ui/react";

export const useCommentRichText = (): SystemStyleObject => {
    const linkGray = useStyleConfig("Link", { variant: "underline", colorScheme: "gray" });

    return {
        "ul, ol": {
            ml: [3, 5],
        },
        li: {
            my: 2,
        },
        a: linkGray,
    };
};
