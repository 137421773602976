// eslint-disable-next-line no-restricted-imports
import { ButtonProps, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IErrorResponse } from "src/api/client/types";
import { useAddWatchlistWikifoliosMutation, useRemoveWatchlistWikifoliosMutation } from "src/api/client/watchlist.api";
import { WfAlertDialog } from "src/components/modals/wf-alert-dialog/wf-alert-dialog";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useUserStore } from "src/stores/user-store";

interface IButtonChildrenProps extends ButtonProps {
    isOnWatchlist?: boolean;
}

interface IWatchlistToggleProps extends Omit<ButtonProps, "isLoading" | "children"> {
    wikifolioId: string;
    wikifolioShortDescription: string;
    setOnWatchlist: (wikifolioId: string, isOnWatchlist: boolean) => void;
    children: (props: IButtonChildrenProps) => React.ReactNode;
    isOnWatchlist?: boolean;
    addedToWatchlistMessage?: string;
    removedFromWatchlistMessage?: string;
}

export const WatchlistToggle = ({
    wikifolioId,
    wikifolioShortDescription,
    isOnWatchlist,
    addedToWatchlistMessage,
    removedFromWatchlistMessage,
    setOnWatchlist,
    children,
}: IWatchlistToggleProps) => {
    const { t } = useTranslation("common");
    const { openLoginModal } = useLinkBuilder();
    const { isLoggedIn } = useUserStore();
    const removeDialog = useDisclosure();
    const toast = useWfToast();

    const onError = (error: IErrorResponse) => {
        toast({
            status: EnumToastStatus.Error,
            title: error?.message || t("general-error"),
        });
    };

    const { mutatePromise: add, isLoading: isLoadingAdd } = useAddWatchlistWikifoliosMutation(wikifolioId, {
        onSuccess: () => {
            toast({ status: EnumToastStatus.Success, title: addedToWatchlistMessage ?? t("add-wikifolio-success") });
            setOnWatchlist(wikifolioId, true);
        },
        onError,
    });

    const { mutatePromise: remove, isLoading: isLoadingRemove } = useRemoveWatchlistWikifoliosMutation(wikifolioId, {
        onSuccess: () => {
            toast({ status: EnumToastStatus.Success, title: removedFromWatchlistMessage ?? t("remove-wikifolio-success") });
            setOnWatchlist(wikifolioId, false);
        },
        onError,
    });

    const handleOnAdd = async () => {
        if (!isLoggedIn) {
            openLoginModal();
            return;
        }

        if (isOnWatchlist) {
            removeDialog.onOpen();
        } else {
            add({});
        }
    };

    const handleRemove = () => {
        removeDialog.onClose();
        remove();
    };

    const isLoading = isLoadingAdd || isLoadingRemove;

    return (
        <>
            {children({ isLoading, onClick: handleOnAdd })}

            <WfAlertDialog
                {...removeDialog}
                onConfirm={handleRemove}
                confirmButtonText={t("remove")}
                confirmButtonProps={{ className: "gtm-watchlist__remove" }}
                wikifolioShortDescription={wikifolioShortDescription}
                header={t("remove-watchlist-header")}
                body={t("remove-watchlist-body")}
            />
        </>
    );
};
